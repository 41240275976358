import React from "react";
import {
	InstantSearch,
	InfiniteHits,
	InstantSearchSSRProvider,
	Configure,
} from "react-instantsearch";
import { RefinementList } from "~/components/algolia-search/refinement-list";
import { Stats } from "~/components/algolia-search/state-results";
import { Hit } from "~/components/algolia-search/hit";
import { Icon } from "../ui/icons";
import type { InstantSearchServerState } from "react-instantsearch";
import { history } from "instantsearch.js/es/lib/routers";
import type { UiState } from "instantsearch.js";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import { getAlgoliaClient } from "~/utils/algoliaConfig";
import { InputError } from "~/components/ui/form/input";
import { Form, useLocation, useSubmit } from "react-router";
import clsx from "clsx";
import { Paragraph } from "../ui/typography";
import { NoResults, NoResultsBoundary } from "./no-result";
import { SearchInput, validateSearchInput } from "./search-input";
import { spTrackSearchTerm } from "~/utils/tracking";
import { HoneyPotInput } from "../honeypot-input";
// import { sanitizeInput } from "~/utils/misc";

type SearchProps = {
	serverState?: InstantSearchServerState;
	serverUrl: string;
	locale: string;
};

const now_timestamp = new Date().getTime();

const { searchClient, searchIndex } = getAlgoliaClient();

export function Search({ serverState, serverUrl, locale }: SearchProps) {
	return (
		<InstantSearchSSRProvider {...serverState}>
			<InstantSearch
				searchClient={searchClient}
				indexName={searchIndex}
				insights
				routing={{
					router: history<UiState>({
						getLocation() {
							if (typeof window === "undefined") {
								return new URL(serverUrl) as unknown as Location;
							}

							return window.location;
						},
						cleanUrlOnDispose: true,
					}),
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					stateMapping: singleIndex(searchIndex) as any,
				}}
				future={{
					preserveSharedStateOnUnmount: true,
				}}
			>
				<Configure
					filters={`__i18n_lang:${locale} AND isHidden:false AND endDate_timestemp > ${now_timestamp}`}
				/>
				<div className="mb-layout2 lg:mb-layout5">
					<SearchInput />
				</div>

				<div className="gap-9 lg:flex">
					<aside
						className="self-start md:sticky lg:w-1/4"
						style={{
							top: `calc(var(--nav-height) + 48px)`,
						}}
					>
						<RefinementList attribute="facetingType" sortBy={["name:asc"]} />
					</aside>
					<div className="lg:w-3/4">
						<Stats />
						<NoResultsBoundary fallback={<NoResults />}>
							<InfiniteHits
								classNames={{
									list: "grid grid-cols-1 gap-6",
									item: "shadow-none p-0",
									loadMore:
										"inline-flex items-center justify-center transition-colors duration-200 ease-in-out rounded-sm border border-transparent text-center font-medium disabled:cursor-not-allowed focus-visible:outline-primary-20 py-3 px-6 min-h-[44px] bg-button-secondary bg-none text-button-secondary border-button-secondary no-underline shadow-none disabled:outline-grey-10 hover:bg-none focus:bg-none focus:border-grey-80 focus:shadow-none font-body text-xs md:text-sm font-medium",
									root: "flex flex-col gap-9 justify-center",
									disabledLoadMore: "hidden",
								}}
								showPrevious={false}
								hitComponent={Hit}
							/>
						</NoResultsBoundary>
					</div>
				</div>
			</InstantSearch>
		</InstantSearchSSRProvider>
	);
}

interface FormElements extends HTMLFormControlsCollection {
	search: HTMLInputElement;

	// honey pot
	language__option: HTMLInputElement;
}

interface SearchFormElement extends HTMLFormElement {
	readonly elements: FormElements;
}

export function SearchButton({ debug = false }: { debug?: boolean }) {
	const [showSearch, setShowSearch] = React.useState(debug);
	const submit = useSubmit();
	const [error, setError] = React.useState("");

	const location = useLocation();

	const isOnSearchPage = location.pathname === "/search";

	const openSearch = () => {
		// focus input when the search opens
		inputRef.current?.focus({ preventScroll: true });

		setShowSearch(true);
	};
	const closeSearch = React.useCallback(() => {
		if (!debug) {
			setShowSearch(false);
		}
	}, [debug]);

	const [query, setQuery] = React.useState<string>("");

	const inputRef = React.useRef<HTMLInputElement>(null);

	const showHelperText = showSearch && Boolean(query);

	React.useEffect(() => {
		if (!showSearch) {
			// clear query when the search closes
			setQuery("");
		}
	}, [showSearch]);

	const keyDownHandler = React.useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape") {
				closeSearch();
			}

			// Ctrl + K to toggle search
			if (event.code === "KeyK" && event.metaKey) {
				setShowSearch((show) => !show);
			}
		},
		[closeSearch]
	);

	React.useEffect(() => {
		window.addEventListener("keydown", keyDownHandler);

		return () => {
			document.removeEventListener("keydown", keyDownHandler);
		};
	}, [keyDownHandler]);

	function handleSubmit(e: React.FormEvent<SearchFormElement>) {
		e.preventDefault();

		// don't peform search when error
		if (error) {
			return;
		}

		const searchTerm = e.currentTarget.elements.search.value;

		// honeypot
		const language__option = e.currentTarget.elements.language__option.value;

		// const sanitizedValue = sanitizeInput(searchTerm);

		spTrackSearchTerm(searchTerm);

		const formData = new FormData();
		formData.set("search", searchTerm);
		formData.set("language__option", language__option);
		submit(formData, {
			method: "post",
			action: "/search",
		});

		// close the input after submitting
		closeSearch();
	}

	// don't show the search button on the search page
	// let the users use the Algolia Search input instead
	if (isOnSearchPage) {
		return null;
	}

	function onSearchChange(event: React.FormEvent<HTMLInputElement>) {
		const inputTarget = event.currentTarget;
		const value = inputTarget.value;
		const errorMessage = validateSearchInput(value);

		setQuery(value);

		if (errorMessage) {
			setError(errorMessage);
		} else {
			setError("");
		}
	}

	return (
		<div className="relative size-9">
			<button
				type="button"
				data-testid="searchbtn"
				aria-label="Click here to open search"
				onClick={openSearch}
				className="absolute flex size-9 items-center justify-center"
			>
				<Icon width="20" height="20" name="search" color="primary" />
			</button>

			<Form onSubmit={handleSubmit} role="search" aria-label="Global">
				<HoneyPotInput name="language__option" />
				<div
					className={clsx(
						"fixed inset-x-6 top-3 overflow-hidden transition-[width] ease-in lg:absolute lg:left-auto lg:right-0 lg:top-0",
						{ "w-auto lg:w-[450px]": showSearch },
						{ "!overflow-visible": showHelperText },
						{ "!w-0 !p-0": !showSearch }
					)}
				>
					{showSearch && (
						<>
							<div className="bg-primary flex h-9 min-h-[44px] w-full items-center justify-between rounded border border-solid border-grey-80 p-3">
								<div className="relative flex h-full flex-row-reverse items-center">
									<input
										autoFocus
										ref={inputRef}
										className={clsx(
											"size-full !min-w-[auto] pl-3 text-sm outline-none"
										)}
										id="global-search"
										type="text"
										role="searchbox"
										name="search"
										placeholder="Search"
										onBlur={closeSearch}
										value={query}
										onChange={onSearchChange}
										aria-invalid={Boolean(error)}
										aria-errormessage="global-search-error"
										aria-describedby={
											showHelperText && !error ? "global-search-info" : ""
										}
									/>

									<button
										className="flex size-7 items-center justify-center"
										type="submit"
										aria-label="Submit"
									>
										<Icon
											name="search"
											color="secondary"
											width="16"
											height="16"
										/>
									</button>
								</div>
								<button
									type="button"
									onClick={closeSearch}
									color="secondary"
									aria-hidden="true"
								>
									<Icon
										name="close-button"
										color="secondary"
										width="14"
										height="14"
									/>
								</button>
							</div>
							{showHelperText ? (
								<div className="bg-primary border-stroke absolute inset-x-0 top-full hidden px-5 py-2 shadow-sm lg:block">
									{error ? (
										<InputError id="global-search-error">{error}</InputError>
									) : (
										<Paragraph
											id="global-search-info"
											size="body-xsmall"
											color="secondary"
											whiteSpacePreLine={false}
											className="!whitespace-nowrap"
										>
											Press Enter to search
										</Paragraph>
									)}
								</div>
							) : null}
						</>
					)}
				</div>
			</Form>
		</div>
	);
}
