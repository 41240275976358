import { algoliasearch } from "algoliasearch";
import { isDevelopment } from "./misc";

function getAlgoliaConfig() {
	const ALGOLIA_PUBLIC_KEY = "4f6b8d256331e031aa894bc42c086f55";

	const ALGOLIA_INDEX =
		ENV.ALGOLIA_INDEX ||
		(isDevelopment() ? "dev_Aiven_Global" : "prod_Aiven_Global");

	return {
		applicationId: ENV.ALGOLIA_APPLICATION_ID,
		publicKey: ALGOLIA_PUBLIC_KEY,
		searchIndex: ALGOLIA_INDEX,
	};
}

export function getAlgoliaClient() {
	const { applicationId, publicKey, searchIndex } = getAlgoliaConfig();

	const isServer = typeof document === "undefined";

	// Use admin key on the server side
	// else public key on the client
	const algloliaKey = isServer ? process.env.ALGOLIA_WRITE_KEY : publicKey;

	return {
		searchClient: algoliasearch(applicationId, algloliaKey),
		searchIndex,
	};
}
