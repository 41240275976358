import React from "react";
import clsx from "clsx";
import { useRefinementList } from "react-instantsearch";
import type { RefinementListProps } from "react-instantsearch";
import { Checkbox } from "~/components/ui/form/checkbox";
import { H4, Paragraph } from "~/components/ui/typography";
import { spTrackFilter } from "~/utils/tracking";
import { Icon } from "~/components/ui/icons";

import { type FacetingType } from "~/types/global-search";
import type { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";

const labelForFacetingType: Record<FacetingType, string> = {
	news: "Press releases & News",
	customerStory: "Customer stories",
	guide: "Tutorials & Guides",
	video: "Webinars & Videos",
	resource: "White papers & Ebooks",
	event: "Upcoming events & workshops",
	documentation: "Documentation",
	post: "Blog posts",
	service: "Services & Solutions",
};

type FilterListProps = {
	items: RefinementListItem[];
	activeFitlers: string[];
	setActiveFilters: React.Dispatch<React.SetStateAction<string[]>>;
	refine: (value: string) => void;
};

function FilterList({
	items,
	activeFitlers,
	setActiveFilters,
	refine,
}: FilterListProps) {
	return (
		<ul className="mt-5 grid grid-cols-2 lg:mt-6 lg:grid-cols-1">
			{items
				.sort((a, b) => {
					const aLabel = labelForFacetingType[a.label as FacetingType] || "";
					const bLabel = labelForFacetingType[b.label as FacetingType] || "";

					if (!aLabel && !bLabel) return 0; // Both are empty
					if (!aLabel) return 1; // Move empty values to the end
					if (!bLabel) return -1; // Move empty values to the end

					return aLabel.localeCompare(bLabel);
				})
				.map((item) => (
					<li key={item.value}>
						<Checkbox
							id={`filter-${item.value}`}
							name={`${item.value}`}
							value={item.value}
							checked={item.isRefined}
							onChange={() => {
								const { value } = item;

								let updatedFilters = [];

								if (activeFitlers.includes(value)) {
									updatedFilters = activeFitlers.filter(
										(item) => item !== value
									);
								} else {
									// if filter is inactive, add it to activeFilters
									updatedFilters = [...activeFitlers, value];
								}

								spTrackFilter(updatedFilters);

								setActiveFilters(updatedFilters);

								refine(item.value);

								window?.scrollTo({
									top: 0,
									behavior: "instant" as ScrollBehavior,
								});
							}}
							className="mb-5 text-xs lg:text-sm"
						>
							{labelForFacetingType[item.label as FacetingType]} ({item.count})
						</Checkbox>
					</li>
				))}
		</ul>
	);
}

function MobileList({
	setOpen,
	open,
	activeFitlers,
	setActiveFilters,
	refine,
	items,
}: FilterListProps & {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	return (
		<>
			<button onClick={() => setOpen((open) => !open)}>
				<div className="flex items-center justify-center gap-3">
					<Paragraph fontWeight="font-semibold">Filter by</Paragraph>
					<Icon
						name="chevron-down"
						color="arrow"
						className={clsx("transition-transform", {
							"-rotate-180": open,
						})}
					/>
				</div>
			</button>
			{open
				? FilterList({ activeFitlers, setActiveFilters, refine, items })
				: null}
		</>
	);
}

function renderDesktopList({
	activeFitlers,
	setActiveFilters,
	refine,
	items,
}: FilterListProps) {
	return (
		<>
			<H4 as="p">Filter by</H4>
			{FilterList({ activeFitlers, setActiveFilters, refine, items })}
		</>
	);
}

export function RefinementList(props: RefinementListProps) {
	const { items, refine } = useRefinementList(props);
	const selectedItems = items
		.filter((item) => item.isRefined)
		.map((item) => item.value);

	const [activeFitlers, setActiveFilters] = React.useState(selectedItems);

	const [open, setOpen] = React.useState(false);

	return (
		<div className="mb-6 lg:mb-layout5">
			<div className="hidden lg:block">
				{renderDesktopList({ activeFitlers, setActiveFilters, refine, items })}
			</div>
			<div className="block lg:hidden">
				{MobileList({
					open,
					setOpen,
					activeFitlers,
					setActiveFilters,
					refine,
					items,
				})}
			</div>
		</div>
	);
}
